import {setToken} from "@/utils/common/index";

// 存储用户登录信息到缓存
export const setUserInfo = ((userInfo) => {
    console.log('用户信息', userInfo)
    setToken(userInfo.token, new Date(new Date().getTime() + 6 * 60 * 60 * 1000))
    delete userInfo.token
    console.log(userInfo)
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    return true;
})
// 获取用户登录信息
export const getUserInfo = (() => {
    return localStorage.getItem("userInfo");
})
// 存储用户信息到缓存
export const setMyInfo = ((userInfo) => {
    // console.log('用户信息', userInfo)
    // setToken(userInfo.token, new Date(new Date().getTime() + 6 * 60 * 60 * 1000))
    // delete userInfo.token
    // console.log('存储用户信息到缓存11',userInfo)
    localStorage.setItem('myInfo', JSON.stringify(userInfo))
    return true;
})
// 获取用户信息
export const getMyInfo = (() => {
    return localStorage.getItem("myInfo");
})